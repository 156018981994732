<script>
import countTo from "vue-count-to";
import Statistics from "@/services/Statistics";
import General from "@/services/General";
import Teams from "@/services/Teams";
import modalEditPreviewText from "@/components/modals/dashboard/modalEditPreviewText";

/**
 * Stat component
 */
export default {
  components: {
    countTo,
    modalEditPreviewText,
  },
  data() {
    return {
      user_count: 0,
      user_count_weekly: 0,
      orders_total: 0.0,
      orders_count: 0,
      change_percent: 0,
      //device_change_percent: 0,
      //device_count_current: 0,
      //device_count_previous: 0,
      sessions_change_percent: 0,
      sessions_count_current: 0,
      sessions_count_previous: 0,
      ticketing_orders_total: 0,
      ticketing_change_percent: 0,
      ticketing_count_current: 0,
      ticketing_count_previous: 0,
      days: 30,
      graph_days: 30,
      graph_months: 12,
      graph_type: "daily",
      graph_data_type: "customerDevices",
      message_text: "",
      message_button_text: "",
      message_button_link: "",
      message_image_url: "",
      message_background_color: "#5b73e8",
      message_text_color: "#ffffff",
      message_show: false,
      dashboard_version: process.env.VUE_APP_VERSION,
      showLoader: false,
      matches: [],
      modalData: {},

      series: [
        {
          data: [0],
        },
      ],
      orders_merchandising: [],
      //            showOrdersStatistics: process.env.VUE_APP_SHOW_ORDER_STATS,
      showOrdersStatistics: false,
      // showOrdersStatistics:true,
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      /*    series: [{
            name: 'series-1',
            data: [30, 40, 45, 50, 49, 60, 70, 91]
          }],

            chartOptions: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            */

      salesSeries: [
        {
          name: "Merchandising",
          type: "column",
          data: [],
        },
        {
          name: "Ewallet",
          type: "area",
          data: [],
        },
        {
          name: "Ticketing",
          type: "line",
          data: [],
        },
      ],

      salesChartOptions: {
        chart: {
          width: "100%",
          height: 390,
          type: "bar",
          stacked: false,
          toolbar: {
            show: false, //hide zoom in-out, export buttons
          },
        },
        noData: {
          text: "Loading...",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
          },
        },
        stroke: {
          width: [1, 2, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: false,
        },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        colors: ["#5b73e8", "#e8e9eb", "#f1b54d"],
        labels: [],
        markers: {
          size: 0,
        },
        xaxis: {
          type: "text",
        },

        /*  yaxis: {
              title: {
                text: '',
              },
           //   min: 0
            }, */

        //2 yaxis
        yaxis: [
          {
            title: {
              text: "",
            },
          },
          {
            opposite: true,
            title: {
              text: "",
            },
          },
        ],

        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function(y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + "";
              }
              return y;
            },
          },
        },
        animations: {
          enabled: true,
          easing: "linear",
          speed: 1500,
          animateGradually: { enabled: true, delay: 1500 },
          dynamicAnimation: { enabled: true, speed: 1500 },
        },
      },

      growthChartOptions: {
        fill: {
          colors: ["#f1b44c"],
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function() {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      orderseries: [70],
      orderRadial: {
        fill: {
          colors: ["#34c38f"],
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
      customerseries: [55],
      customerRadial: {
        fill: {
          colors: ["#5b73e8"],
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
    };
  },
  computed: {
    // hideDelimiters() {
    //   return this.matches.length > 1 ? false : true;
    // },
    showArrows() {
      return this.matches.length > 1 ? true : false;
    },
  },
  async mounted() {
    this.getCustomerDevicesStatistics();
    this.getOrdersStatisticsByTypeTotalCount();
    this.getCustomerStatistics();
    this.getOrdersTotalsStatistics();
    //this.getDevicesStatistics()
    this.getSessionsStatistics();
    this.getTicketingTotalsStatistics();
    //        this.getOrdersStatisticsByType()

    this.getDashboardMessage();
    this.getSportMatchesCurrent();
  },
  methods: {
    async getCustomerStatistics() {
      try {
        await Statistics.getCustomerStatistics(this.days, {})
          .then((response) => {
            this.user_count = response.data.data.user_count;
            this.user_count_weekly = response.data.data.user_count_weekly;
          })
          .catch((error) => {
            this.error = error.response.data.error
              ? error.response.data.error
              : "";
            this.failedmsg(this.error);
          })
          .finally(() => {});
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
        this.failedmsg(this.error);
      }
    },

    /*  async getDevicesStatistics(){
            try {
                await Statistics.getDevicesStatistics(this.days,{

                }).then((response) => {
                    this.device_count_current = response.data.data.devices_count;
                    this.device_count_previous = response.data.data.compare_count;
                    this.device_change_percent = response.data.data.change_percent;
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }).finally(() => {
                })

            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.failedmsg(this.error)
            }
        },*/

    async getSessionsStatistics() {
      try {
        await Statistics.getSessionsStatistics(this.days, {})
          .then((response) => {
            this.sessions_count_current = response.data.data.sessions_count;
            this.sessions_count_previous = response.data.data.compare_count;
            this.sessions_change_percent = response.data.data.change_percent;
          })
          .catch((error) => {
            this.error = error.response.data.error
              ? error.response.data.error
              : "";
            this.failedmsg(this.error);
          })
          .finally(() => {});
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
        this.failedmsg(this.error);
      }
    },

    async getOrdersTotalsStatistics() {
      try {
        await Statistics.getOrdersTotalStatistics(this.days, {})
          .then((response) => {
            let orders_total_temp = response.data.data.orders_total;
            this.orders_total = parseFloat(orders_total_temp);
            this.orders_count = response.data.data.orders_count;
            this.change_percent = response.data.data.change_percent;

            let orders_per_day = response.data.data.orders_per_day;

            this.series[0].data.splice(0, 1);
            orders_per_day.forEach((order) => {
              //orders_temp.push(parseFloat(order.orders_total));
              this.series[0].data.push(parseFloat(order.orders_total));
            });
          })
          .catch((error) => {
            this.error = error.response.data.error
              ? error.response.data.error
              : "";
            this.failedmsg(this.error);
          })
          .finally(() => {
            this.updateSeriesLine();
          });
      } catch (error) {
        //console.log(error)
      }
    },

    async getTicketingTotalsStatistics() {
      try {
        await Statistics.getTicketingTotalStatistics(this.days, {})
          .then((response) => {
            let ticketing_total_temp = response.data.data.orders_total;
            this.ticketing_orders_total = parseFloat(ticketing_total_temp);
            this.ticketing_count = response.data.data.orders_count;
            this.ticketing_change_percent = response.data.data.change_percent;
            let ticketing_orders_per_day = response.data.data.orders_per_day;
          })
          .catch((error) => {
            this.error = error.response.data.error
              ? error.response.data.error
              : "";
            this.failedmsg(this.error);
          })
          .finally(() => {});
      } catch (error) {
        //console.log(error)
      }
    },

    async getOrdersStatisticsByType() {
      this.showLoader = true;
      try {
        await Statistics.getOrdersStatisticsByType(
          this.graph_days,
          this.graph_type,
          {}
        )
          .then((response) => {
            this.updateSalesChart(response.data.data);
          })
          .catch((error) => {
            this.error = error.response.data.error
              ? error.response.data.error
              : "";
            this.failedmsg(this.error);
          })
          .finally(() => {
            this.showLoader = false;
          });
      } catch (error) {
        //console.log(error)
      }
    },

    async getOrdersStatisticsByTypeTotalCount() {
      try {
        const response = await Statistics.getOrdersStatisticsByType(
          this.graph_days,
          this.graph_type,
          {}
        );
        if (response.data.data.data.length > 0) {
          this.showOrdersStatistics = true;
        } else {
          this.showOrdersStatistics = false;
        }
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
        this.failedmsg(this.error);
        this.showOrdersStatistics = false;
      }
    },

    async getCustomerDevicesStatistics() {
      this.showLoader = true;
      try {
        await Statistics.getCustomerDevicesStatistics(
          this.graph_days,
          this.graph_type,
          {}
        )
          .then((response) => {
            this.updateSalesChart(response.data.data);
          })
          .catch((error) => {
            this.error = error.response.data.error
              ? error.response.data.error
              : "";
            this.failedmsg(this.error);
          })
          .finally(() => {
            this.showLoader = false;
          });
      } catch (error) {
        //console.log(error)
      }
    },

    updateSeriesLine() {
      this.$refs.realtimeChart.updateSeries(
        [
          {
            data: this.series[0].data,
          },
        ],
        false,
        true
      );
    },

    goToURL(url) {
      window.location.href = "/" + url;
    },

    updateSalesChart(data) {
      this.salesSeries = data.data;
      if (this.graph_data_type == "customerDevices") {
        this.salesChartOptions = {
          labels: data.labels,
          colors: ["#5b73e8", "#f1b54d"],
          stroke: {
            width: [1, 5],
          },
          fill: {
            opacity: [0.85, 1],
          },

          yaxis: {
            title: {
              text: "",
            },
            //   min: 0
          },
        };
      } else if (this.graph_data_type == "orders") {
        this.salesChartOptions = {
          labels: data.labels,
          colors: ["#5b73e8", "#FF0000", "#f1b54d"],

          stroke: {
            width: [0, 2, 5],
          },
          fill: {
            opacity: [0.85, 0.25, 1],
          },
          //2 yaxis
          yaxis: [
            {
              title: {
                text: "",
              },
            },
            {
              opposite: true,
              title: {
                text: "",
              },
            },
          ],
        };
      }
    },

    getGraphStatistics() {
      if (this.graph_data_type == "orders") {
        this.getOrdersStatisticsByType(this.graph_days, "daily");
      } else if (this.graph_data_type == "customerDevices") {
        this.getCustomerDevicesStatistics(this.graph_days, "daily"); //create this!
      }
    },

    getOrdersStatistics() {
      if (this.graph_data_type == "orders") {
        if (this.graph_type == "daily") {
          this.getOrdersStatisticsByType(this.graph_days, "daily");
        } else if (this.graph_type == "monthly") {
          this.getOrdersStatisticsByType(this.graph_months, "monthly"); //create this!
        }
      } else if (this.graph_data_type == "customerDevices") {
        if (this.graph_type == "daily") {
          this.getCustomerDevicesStatistics(this.graph_days, "daily");
        } else if (this.graph_type == "monthly") {
          this.getCustomerDevicesStatistics(this.graph_months, "monthly"); //create this!
        }
      }
    },

    async getDashboardMessage() {
      this.message_show = false;
      try {
        await General.getDashboardMessage(this.dashboard_version, {})
          .then((response) => {
            this.message_text = response.data.data.text;
            this.message_button_text = response.data.data.button_text;
            this.message_button_link = response.data.data.button_link;
            this.message_image_url = response.data.data.img_url;
            this.message_background_color = response.data.data.background_color;
            this.message_text_color = response.data.data.text_color;
            this.message_show = true;
          })
          .catch((error) => {
            this.error = error.response.data.error
              ? error.response.data.error
              : "";
            this.failedmsg(this.error);
          })
          .finally(() => {});
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
        this.failedmsg(this.error);
      }
    },
    formatDateTime(dateString) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };

      const date = new Date(dateString);
      const formattedDate = date
        .toLocaleDateString("de-DE", options)
        .replace(/\./g, "."); // DD.MM.YYYY
      const formattedTime = date.toLocaleTimeString("en-GB", timeOptions); // HH:MM

      return `${formattedDate} ${formattedTime}`;
    },

    async getSportMatchesCurrent() {
      try {
        const response = await Teams.getSportMatchesCurrent();
        // Format date_time for each match
        this.matches = response.data.matches.map((match) => ({
          ...match,
          date_time: this.formatDateTime(match.date_time),
        }));
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },
    callModalEditPreviewText(data) {
      this.$bvModal.show("edit_preview_text");
      this.modalData = data;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-6 col-xl-3">
      <div class="card">
        <div class="card-body elementPointed" @click="goToURL('customers')">
          <div class="float-end mt-2">
            <i class="fas fa-users fa-3x"></i>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              <span data-plugin="counterup">
                <countTo
                  :startVal="0"
                  :endVal="user_count"
                  :duration="2000"
                ></countTo>
              </span>
            </h4>
            <h5 class="text-muted mb-0">Registered Users</h5>
          </div>
          <p class="text-muted mt-3 mb-0">
            <span v-if="user_count_weekly > 0" class="text-success me-1">
              <i class="mdi mdi-arrow-up-bold me-1 font-size-16"></i
              >{{ user_count_weekly }}
            </span>
            <span v-else class="text-danger me-1 font-size-16">
              <i class="mdi mdi-arrow-down-bold me-1 font-size-16"></i
              >{{ user_count_weekly }}</span
            >
            last {{ days }} days
          </p>
        </div>
      </div>
    </div>

    <!--
    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body elementPointed">
                <div class="float-end mt-2">
                    <i class="fas fa-mobile-alt fa-3x"></i>
                </div>
                <div>
                    <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">
                            <countTo :startVal="0" :endVal="device_count_current" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <h5 class="text-muted mb-0">Active devices ({{days}} days)</h5>
                </div>
                <p class="text-muted mt-3 mb-0">
                    <span v-if="device_change_percent >= 0" class="text-success me-1">
                       <i class="mdi mdi-arrow-up-bold me-1 font-size-16"></i>{{device_change_percent}}%
                    </span>
                    <span v-else class="text-danger me-1 font-size-16">
                        <i class="mdi mdi-arrow-down-bold me-1 font-size-16"></i>{{device_change_percent}}%</span>
                    vs. previous {{days}} days
                </p>
            </div>
        </div>
    </div>
-->
    <div class="col-md-6 col-xl-3">
      <div class="card">
        <div class="card-body elementPointed">
          <div class="float-end mt-2">
            <i class="fas fa-mobile-alt fa-3x"></i>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              <!--  <span v-if="sessions_count_current === null" class="me-1">
                         N/A
                       </span>
                      <span v-else data-plugin="counterup">
                            <countTo :startVal="0" :endVal="sessions_count_current" :duration="2000"></countTo>
                        </span> -->
              <span>TBD</span>
            </h4>
            <h5 class="text-muted mb-0">Active sessions ({{ days }} days)</h5>
          </div>
          <p><span class="me-1"></span></p>
          <!-- <p class="text-muted mt-3 mb-0">
                   <span v-if="sessions_count_current === null" class="me-1"></span>
                   <span v-else-if="sessions_change_percent === null" class="me-1">
                      <i class="fas fa-info-circle me-1 font-size-16"></i>No data to compare with
                   </span>
                   <span v-else-if="sessions_change_percent >= 0" class="text-success me-1">
                      <i class="mdi mdi-arrow-up-bold me-1 font-size-16"></i>{{sessions_change_percent}}% vs. previous {{days}} days
                   </span>
                   <span v-else class="text-danger me-1 font-size-16">
                       <i class="mdi mdi-arrow-down-bold me-1 font-size-16"></i>{{sessions_change_percent}}% vs. previous {{days}} days</span>

               </p> -->
        </div>
      </div>
    </div>

    <div class="col-md-6 col-xl-3">
      <div class="card">
        <div class="card-body elementPointed" @click="goToURL('orders')">
          <div class="float-end mt-2">
            <i class="fas fa-credit-card fa-3x"></i>
            <!--  <apexchart v-if="orders_total > 0" ref="realtimeChart" class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions" :series="series"></apexchart>
                    <i v-else class="fas fa-credit-card fa-3x"></i> -->
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              <span data-plugin="counterup">
                &euro;<countTo
                  :startVal="0.0"
                  :endVal="orders_total"
                  :decimals="2"
                  decimal="."
                  :duration="2000"
                ></countTo>
              </span>
            </h4>
            <h5 class="text-muted mb-0">Orders ({{ days }} days)</h5>
          </div>
          <p class="text-muted mt-3 mb-0">
            <!-- <span class="me-1 text-primary font-size-16">{{orders_count > 0 ? orders_count : 0 }}</span> total orders -->

            <span v-if="change_percent === null" class="me-1">
              <i class="fas fa-info-circle me-1 font-size-16"></i>No data to
              compare with
            </span>
            <span v-else-if="change_percent >= 0" class="text-success me-1">
              <i class="mdi mdi-arrow-up-bold me-1 font-size-16"></i
              >{{ change_percent }}% vs. previous {{ days }} days
            </span>
            <span v-else class="text-danger me-1 font-size-16">
              <i class="mdi mdi-arrow-down-bold me-1 font-size-16"></i
              >{{ change_percent }}% vs. previous {{ days }} days</span
            >
          </p>
        </div>
      </div>
    </div>
    <!-- end col-->

    <div class="col-md-6 col-xl-3">
      <div class="card">
        <div class="card-body elementPointed">
          <div class="float-end mt-2">
            <i class="fas fa-ticket-alt fa-3x"></i>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              <span data-plugin="counterup">
                &euro;<countTo
                  :startVal="0.0"
                  :endVal="ticketing_orders_total"
                  :decimals="2"
                  decimal="."
                  :duration="2000"
                ></countTo>
              </span>
            </h4>
            <h5 class="text-muted mb-0">Ticketing ({{ days }} days)</h5>
          </div>
          <p class="text-muted mt-3 mb-0">
            <span v-if="ticketing_change_percent === null" class="me-1">
              <i class="fas fa-info-circle me-1 font-size-16"></i>No data to
              compare with
            </span>
            <span
              v-else-if="ticketing_change_percent >= 0"
              class="text-success me-1"
            >
              <i class="mdi mdi-arrow-up-bold me-1 font-size-16"></i
              >{{ ticketing_change_percent }}% vs. previous {{ days }} days
            </span>
            <span v-else class="text-danger me-1 font-size-16">
              <i class="mdi mdi-arrow-down-bold me-1 font-size-16"></i
              >{{ ticketing_change_percent }}% vs. previous
              {{ days }} days</span
            >
          </p>
        </div>
      </div>
    </div>
    <!-- end col-->

    <!--!!!sales chart is used to plot customers and devices data also-->
    <!--  <div class="row"> -->
    <div class="col-xl-8" :style="{ width: '100%;' }">
      <div class="card">
        <div class="card-body">
          <div class="col-sm-4 float-start">
            <template v-if="showOrdersStatistics == true">
              <select
                v-model="graph_data_type"
                class="form-select"
                @change="getGraphStatistics()"
              >
                <option value="customerDevices" selected
                  >New Registered Users</option
                >
                <option value="orders">Orders</option>
              </select>
            </template>
            <template v-else>
              <select
                v-model="graph_data_type"
                class="form-select"
                @change="getGraphStatistics()"
              >
                <option value="customerDevices" selected
                  >New Registered Users</option
                >
              </select>
            </template>
          </div>
          <div class="col-sm-2 float-end">
            <select
              v-model="graph_type"
              class="form-select"
              @change="getOrdersStatistics()"
            >
              <option value="daily" selected>Daily</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          <br />

          <div class="mt-3 p-2">
            <div
              class="card-body d-flex align-items-center justify-content-center"
              v-if="showLoader"
            >
              <!--  <b-spinner large></b-spinner> -->
            </div>
            <apexchart
              id="salesChart"
              data-colors='["--bs-primary", "#dfe2e6", "--bs-warning"]'
              class="apex-charts"
              ref="salesChart"
              :options="salesChartOptions"
              :series="salesSeries"
            >
            </apexchart>
          </div>
        </div>
        <!-- end card-body-->
      </div>
      <!-- end card-->
    </div>
    <!-- end col-->

    <div class="col-xl-4" v-if="message_show">
      <div
        class="card"
        :style="{ 'background-color': message_background_color }"
      >
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-sm-8">
              <p
                class="font-size-18"
                :style="{ color: message_text_color }"
                v-html="message_text"
              ></p>
              <div class="mt-4">
                <a
                  :href="this.message_button_link"
                  class="btn btn-success waves-effect waves-light"
                  >{{ this.message_button_text }}</a
                >
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mt-4 mt-sm-0">
                <img :src="this.message_image_url" class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- end card-body-->
      </div>
      <!-- end card-->
      <v-app v-if="matches.length">
        <v-container>
          <v-carousel
            :cycle="false"
            light
            interval="3000"
            :show-arrows="showArrows"
            class="dynamic-height-carousel"
            hide-delimiters
          >
            <v-carousel-item v-for="match in matches" :key="match.match_id">
              <v-sheet>
                <div class="custom-carousel-item">
                  <div class="card card-no-shadow">
                    <div class="card-body">
                      <div class="row align-items-center mb-3">
                        <h3 class="text-center">Current Match</h3>
                        <p class="card-text text-center">
                          {{ match.competition_name }}
                        </p>
                        <p
                          v-if="match.match_status === 'Played'"
                          class="card-text text-center"
                        >
                          {{ match.match_status }}
                        </p>
                        <p v-else class="card-text text-center">
                          {{ match.date_time }}
                        </p>
                        <div class="col-sm-5 text-center">
                          <img
                            :src="match.home_team_logo"
                            class="img-fluid"
                            alt="Home team logo"
                            style="max-height: 75px;"
                          />
                          <p class="card-title">{{ match.home_team_name }}</p>
                        </div>
                        <div
                          class="col-sm-2 text-center d-flex justify-content-center align-items-center"
                        >
                          <p class="card-title m-0">
                            <span v-if="match.match_status === 'Played'">
                              {{ match.score_home }} : {{ match.score_away }}
                            </span>
                            <span v-else>
                              <a
                                href="javascript:void(0);"
                                class="text-info"
                                v-b-tooltip.hover
                                title="View preview text"
                                @click="callModalEditPreviewText(match)"
                              >
                                Match Preview
                              </a>
                            </span>
                          </p>
                        </div>

                        <div class="col-sm-5 text-center">
                          <img
                            :src="match.away_team_logo"
                            class="img-fluid"
                            alt="Away team logo"
                            style="max-height: 75px;"
                          />
                          <p class="card-title">{{ match.away_team_name }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- end card-body-->
                  </div>
                  <!-- end card-->
                </div>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
          <!-- <div class="carousel-indicators">
                  <button
                      v-for="(match, index) in matches"
                      :key="index"
                      :class="['indicator', { active: index === activeSlide }]"
                      @click="goToSlide(index)"
                  ></button>
                </div>-->
        </v-container>
      </v-app>
    </div>
    <!-- end col-->
    <modalEditPreviewText
      :fixture="modalData"
      @onRefresh="getSportMatchesCurrent"
    ></modalEditPreviewText>
  </div>
  <!-- end row-->
</template>
<style scoped>
.v-carousel__controls {
  background-color: red;
}
</style>
